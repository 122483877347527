<template>
    <div>
        <h1>Offices Invoice Statements</h1>
    </div>
</template>

<script>
    export default {
        name: 'OfficeInvoiceStatements'
    };
</script>
